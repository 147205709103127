import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import Layout from '../components/Layout';
import TagTemplateDetails from '../components/TagTemplateDetails';

import { MetaFragment } from '../components/Meta';

const TagTemplate = props => {
  const { data, pageContext } = props;
  const { tag, uri, pageNumber } = pageContext;
  const { wp, site, wpTag, file } = data; 
  const { title: siteTitle, url: siteUrl, language } = wp.generalSettings;
  const { seo } = wpTag;

  const featuredImage = {
    node: {
      mediaItemUrl: siteUrl + file.publicURL,
      mediaDetails: {
        width: 1863,
        height: 943,
      },
    },
  }; 

  Object.entries(seo).forEach(([key, value]) => {
    if (!value || value.length === 0)
      delete seo[key];
  });


  const link = siteUrl + uri +  (pageNumber > 0 ? `/Seite/${pageNumber+1}` : '');  
  const title = seo.title ?? `${tag} - ${parse(siteTitle)}`;
  const description = seo.metaDesc ?? (wpTag.description || `Alle Beiträge, die mit ${tag} getagged sind`);
  const type = `article`;
  const ogImg = image => image ?? featuredImage.node;

  const jsonLd = {
    "@context": `https://schema.org/`,
    "@type": type,
    url: link,
    headline: title,
    publisher: {
        "@type": `Organization`,
        name: siteTitle,
        logo: {
            "@type": `ImageObject`,
            url: `${siteUrl}/wp-content/uploads/2021/09/logo-sm.png`,
            width: 1684,
            height: 240,
        },
    },
    image: {
      "@type": `ImageObject`,
      url: featuredImage.node.mediaItemUrl,
      width: featuredImage.node.mediaDetails.width,
      height: featuredImage.node.mediaDetails.height,
    },
  };

  return (
    <Layout>
      <div>
        <Helmet>
          <html lang={language} />
          <title>{title}</title>
          <link rel="canonical" href={link} />    
          <link rel="pingback" href="https://xmalanderssein.de/xmlrpc" />           
          <meta name="description" content={description} />          
          <meta name="robots" content={site.siteMetadata.robots.join(',')} />
          <meta property="og:site_name" content={siteTitle} />
          <meta property="og:type" content={type} />
          <meta property="og:title" content={seo.opengraphTitle ?? title} />
          <meta property="og:description" content={seo.opengraphDescription ?? description} />
          <meta property="og:url" content={link} />
          <meta name="twitter:title" content={seo.twitterTitle ?? (seo.opengraphTitle ?? title)} />
          <meta name="twitter:description" content={seo.twitterDescription ?? (seo.opengraphDescription ?? description)} />
          <meta name="twitter:url" content={link} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image" content={ogImg(seo.twitterImage).mediaItemUrl} />
          <meta property="og:image" content={ogImg(seo.opengraphImage).mediaItemUrl} />
          <meta property="og:image:width" content={ogImg(seo.opengraphImage).mediaDetails.width} />
          <meta property="og:image:height" content={ogImg(seo.opengraphImage).mediaDetails.height} />
					<meta name="google-site-verification" content="l5FCVcaO9LO7rocX46rGmx_wtbWEjqUOTQE4DibrMJA" />
          <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
        </Helmet>
        <TagTemplateDetails {...props} />
      </div>
    </Layout>
  );
};

export default TagTemplate;

export const pageQuery = graphql`
  query($tag: String!, $limit: Int!, $skip: Int!) {
    site {
      siteMetadata {   
        adminUrl     
        rss
        robots
      }
    }
    file(name: {eq: "logo-wp"}) {
      publicURL
    }
    wpTag(name: {eq: $tag}) {
      link
      description
      seo {
        ...WpTaxonomySEOFragment
      }
    }
    allWpPost(
      filter: {tags: {nodes: {elemMatch: {name: {eq: $tag}}}}}
      limit: $limit
      sort: {fields: date, order: DESC}
      skip: $skip
    ) {
      edges {
        node {
          id
          databaseId
          uri
          title
          date(formatString: "DD.MM.YYYY")
          excerpt
          slug
          modified(formatString: "DD.MM.YYYY HH:mm:ss")
          link
          author {
            node {
              name
              nickname
              avatar {
                url
              }
            }
          }
          categories {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    layout: CONSTRAINED
                    transformOptions: {fit: CONTAIN}
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
    allWpPage {
      edges {
        node {
          uri
          title
        }
      }
    }
    allWpCategory {
      nodes {
        name
        uri
      }
    }
    wp {
      generalSettings {
        description
        language
        title
        url
      }
    }    
  }
`;
