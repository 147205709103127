import React from 'react';
import Header from './Header';
import Feed from './Feed';
import Pagination from './Pagination';
import InstaFeed from './InstaFeed';
import Footer from './Footer';

const TagTemplateDetails = props => {
  const { data, pageContext } = props;
  const { allWpPost, allWpCategory } = data;
  const { tag, uri } = pageContext;

  const categoryNames = allWpCategory.nodes
    .map(node => ({ name: node.name, uri: node.uri }))
    .filter(({ name }) => name !== 'Uncategorized');

  const posts = allWpPost.edges.map(edge => edge.node);

  return (
    <>
      <Header location="/" title={`Tag - ${tag}`} />   
      <header className="container container--wide post">
        <h1>{tag}</h1>
      </header>      
      <main className="container container--wide grid">          
        <Feed posts={posts} />
      </main>
      <Pagination pageContext={props.pageContext} />  
      <InstaFeed />
      <Footer categories={categoryNames} />
    </>
  );
};

export default TagTemplateDetails;
